<template>
  <div class="preview-survey" id="survey-scroller">
    <template v-if="!error && question">
      <section class="section">
        <div class="columns page-wrap is-centered is-vcentered">
          <transition mode="out-in" name="page-component">
            <component
                :is="`Q${question.type}`"
                :lang="lang"
                :question="question"
                :show-question-code="showQuestionCode"
                :show-answer-code="showAnswerCode"
                :value="changes"
                @input="onChange"
            ></component>
          </transition>
        </div>
      </section>
    </template>
    <ErrorMessage :error="error"></ErrorMessage>
  </div>
</template>

<script>
import { getSurveyTemplateQuestion } from '@/api/response'
import { QuestionTypeComponentsMixin } from '@/mixins/question-type-components'
import { ErrorHandlerMixin } from '@/mixins/error-handler'
import { LocaleSwitchingMixin } from '@/mixins/locale-switching'
import { TemplateMixin } from '@/mixins/template'

export default {
  name: 'PreviewTemplateQuestion',
  mixins: [
    QuestionTypeComponentsMixin,
    ErrorHandlerMixin,
    LocaleSwitchingMixin,
    TemplateMixin,
  ],
  data () {
    return {
      question: null,
    }
  },
  methods: {
    async init () {
      this.error = null
      const loadingComponent = this.$buefy.loading.open()
      try {
        const { params: { code, questionCode } } = this.$route
        if (code && questionCode) {
          await this.getSurveyTemplate()
          const { data: { data } } = await getSurveyTemplateQuestion(code, questionCode)
          this.question = data
        }
      } catch (error) {
        this.errorHandler(error)
      } finally {
        loadingComponent.close()
      }
    },
  },
  created () {
    this.init()
  }
}
</script>
